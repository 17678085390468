export function popups(){
	var videoModalTriggers = document.querySelectorAll('.modal-video-trigger');
	var popupTriggers = document.querySelectorAll('a[href*="#popup-');

	var popups = document.querySelectorAll('.modal, aside');
	popups.forEach(function(el, index){
		el.style.display = '';
	});	

	window.mmOnOpen = function(modal){
		document.documentElement.classList.add('modal-open')
	}

	window.mmOnClose = function(modal){
		document.documentElement.classList.remove('modal-open');
		pauseAllVideos();
	}
	
	MicroModal.init({
		onShow: window.mmOnOpen, 
		onClose: window.mmOnClose
	});

	videoModalTriggers.forEach(function(el, index){
		el.addEventListener('click', function(){
			var newVideoURL = '';
			var videoID = el.dataset.videoId;
			var thisModalID = el.dataset.micromodalTrigger;
			var thisModalEl = document.querySelector('#'+thisModalID);

			if(el.classList.contains('vimeo')){

                var iframeHTML = '<iframe src="https://player.vimeo.com/video/' + videoID + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
       
            } else if(el.classList.contains('wistia')){

                var iframeHTML = '<div class="wistia_embed wistia_async_'+videoID+'"></div>';
                _wq.push({ 
                    id: videoID, 
                    onReady: function(video) {
                    video.play();
                }});

            } else { 
                var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + videoID + '?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
            }

            if(thisModalEl){
            	var thisModalCont = thisModalEl.querySelector('.video-container');
            	thisModalCont.innerHTML = iframeHTML;
            }
		});
	});

	popupTriggers.forEach(function(el, index){
		el.addEventListener('click', function(e){
			e.preventDefault();

			// var thisModal = document.querySelector(el.getAttribute('href'));
			// thisModal.classList.add('is-really-open');

			var modalID = el.getAttribute('href').replace('#','');

			MicroModal.show(modalID);

			// load calendly script
			var thisLazyScript = document.querySelector('#'+modalID + ' [data-cldly-src]');
			thisLazyScript.setAttribute('src', thisLazyScript.dataset.cldlySrc);
		});
	});

	function pauseAllVideos(){
		var allModals = document.querySelectorAll('.modal--video');

		allModals.forEach(function(el, index){
			el.querySelector('.video-container').innerHTML = '';
		});
	}
}